import { useEffect } from "react";
import $ from "jquery";
import Slider from "react-slick";
import Countdown from "react-countdown-simple";
import { Link } from "react-router-dom";
var mCustomScrollbar = require("malihu-custom-scrollbar-plugin");
// import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

// require('jquery-mousewheel')

const HomePage = () => {
  var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const targetDate = new Date(
    new Date().setHours(new Date().getHours() + 48)
  ).toISOString();
  useEffect(() => {
    $(".bt-roadmap_x").mCustomScrollbar({
      axis: "x",
      scrollbarPosition: "outside",
      theme: "custom-bar3",
      scrollInertia: 100,
      advanced: {
        autoExpandHorizontalScroll: 2,
      },
    });
  }, []);
  return (
    <main className="main-area">
      <div
        data-elementor-type="wp-page"
        data-elementor-id="57"
        className="elementor elementor-57"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-17b794a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="17b794a"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61c4005"
              data-id="61c4005"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1b94da1 elementor-widget elementor-widget-hero-banner"
                  data-id="1b94da1"
                  data-element_type="widget"
                  data-widget_type="hero-banner.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- banner-area --> */}
                    <section className="banner-area banner-bg">
                      <div className="banner-shape-wrap">
                        <img
                          decoding="async"
                          src="./wp-content/uploads/2023/03/banner_shape01.png"
                          alt=""
                          className="img-one"
                        />
                        <img
                          decoding="async"
                          src="./wp-content/uploads/2023/03/banner_shape02.png"
                          alt=""
                          className="img-two"
                        />
                        <img
                          decoding="async"
                          src="./wp-content/uploads/2023/03/banner_shape03.png"
                          alt=""
                          className="img-three"
                        />
                      </div>
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-10">
                            <div className="banner-content text-center">
                              <img
                                decoding="async"
                                src="./wp-content/uploads/2023/03/fire.png"
                                alt=""
                              />

                              <h2 className="title">
                                Message for loan apply with
                                <span>Wolflender</span>
                              </h2>
                            </div>
                            {/*  #FF9700 #12D176 */}

                            <div className="row mb-5">
                              <div className="col-md-4 d-flex flex-column align-items-center mt-4 mt-md-0">
                                <span style={{ color: "#00c4f4" }}>
                                  LOAN APPLIED
                                </span>
                                <div
                                  style={{
                                    height: 20,
                                    width: 2,
                                    background: "#00c4f4",
                                  }}
                                ></div>
                                <span>Till now 45000 loan requests </span>
                              </div>
                              <div className="col-md-4  d-flex flex-column align-items-center mt-4 mt-md-0">
                                <span style={{ color: "#FF9700" }}>
                                  NUMBER OF APPROVED
                                </span>
                                <div
                                  style={{
                                    height: 20,
                                    width: 2,
                                    background: "#FF9700",
                                  }}
                                ></div>
                                <span>Till now 45000 loan requests </span>
                              </div>
                              <div className="col-md-4  d-flex flex-column align-items-center mt-4 mt-md-0">
                                <span style={{ color: "#12D176" }}>
                                  TOTAL AMOUNT DISBURSED
                                </span>
                                <div
                                  style={{
                                    height: 20,
                                    width: 2,
                                    background: "#12D176",
                                  }}
                                ></div>
                                <span>Till now 45000 loan requests </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-10">
                            <div className="banner-countdown-wrap text-center">
                              <h2 className="title">Apply Soon</h2>

                              <Countdown
                                targetDate={targetDate}
                                renderer={({
                                  days,
                                  hours,
                                  minutes,
                                  seconds,
                                }) => {
                                  return (
                                    <div
                                      className="coming-time"
                                      data-countdown="2023/8/29"
                                    >
                                      <div className="time-count day">
                                        <span>{days}</span>Days
                                      </div>
                                      <div className="time-count hour">
                                        <span>{hours}</span>hour
                                      </div>
                                      <div className="time-count min">
                                        <span>{minutes}</span>minute
                                      </div>
                                      <div className="time-count sec">
                                        <span>{seconds}</span>second
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- banner-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-4ab2708 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="4ab2708"
          data-element_type="section"
          id="about"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgroundColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9a9602d"
              data-id="9a9602d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9872395 elementor-widget elementor-widget-about"
                  data-id="9872395"
                  data-element_type="widget"
                  data-widget_type="about.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- about-area --> */}
                    <section id="about" className="about-area">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div
                              className="about-img wow fadeInLeft"
                              data-wow-delay=".2s"
                            >
                              <img
                                decoding="async"
                                src="./wp-content/uploads/2023/03/about_img01.png"
                                alt=""
                              />
                              <img
                                decoding="async"
                                src="./wp-content/uploads/2023/03/about_img02.png"
                                alt=""
                                className="img-two"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div
                              className="about-content wow fadeInRight"
                              data-wow-delay=".2s"
                            >
                              <div className="section-title mb-30">
                                <span className="sub-title">Anything here</span>

                                <h2 className="title">
                                  Message for easy crypto loan apply
                                </h2>
                              </div>

                              <p>
                                The World’s 1st ICO Platform That Offers Rewards
                                and The platform helps investors to make easy to
                                purchase and sell their tokens
                              </p>
                              <Link to={"/apply"} className="btn">
                                Apply Now{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- about-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-5 elementor-section elementor-top-section elementor-element elementor-element-f4ac265 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="f4ac265"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ed8b1e"
              data-id="6ed8b1e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-0561d11 elementor-widget elementor-widget-brand"
                  data-id="0561d11"
                  data-element_type="widget"
                  data-widget_type="brand.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- partner-area --> */}
                    <div className="partner-area">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="section-title text-center mb-10">
                              <span className="sub-title">
                                We support bitcoin currency
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="partner-wrap">
                              <ul>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img01.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img02.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img03.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img04.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img05.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img06.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img07.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img08.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img09.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img10.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- partner-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e234df9 elementor-section-full_width choose-area elementor-section-height-default elementor-section-height-default"
          data-id="e234df9"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f1c6e5e"
              data-id="f1c6e5e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-a7884f5 elementor-widget elementor-widget-tp-heading"
                  data-id="a7884f5"
                  data-element_type="widget"
                  data-widget_type="tp-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="section-title">
                      <span className="sub-title">why Choose us</span>
                      <h2 className="title">
                        Why choose <span>Wolflender</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4f7283c elementor-widget elementor-widget-features"
                  data-id="4f7283c"
                  data-element_type="widget"
                  data-widget_type="features.default"
                >
                  <div className="elementor-widget-container">
                    <div className="container">
                      <div className="row choose-active">
                        <Slider {...sliderSettings}>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon01.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Mobile payment make easy
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon02.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Lifetime free transaction
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon03.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Protect the <br /> identity
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon04.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Security &amp; control over money
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon02.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Lifetime free transaction
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                      <div className="slide-progress" role="progressbar">
                        <span className="slider__label sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-27a83b6 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="27a83b6"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgroundColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-36f7b46"
              data-id="36f7b46"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-591fde4 elementor-widget elementor-widget-tg-buy"
                  data-id="591fde4"
                  data-element_type="widget"
                  data-widget_type="tg-buy.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- chart-area --> */}
                    <section
                      id="sales"
                      className="chart-area chart-bg jarallax"
                    >
                      <div className="container">
                        <div className="chart-inner">
                          <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                              <div className="list-group">
                                <a
                                  href="#"
                                  className="list-group-item list-group-item-action"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1 text-white">
                                      Lender 1 Name
                                    </h5>
                                    <small className="text-white">
                                      4 Rating
                                    </small>
                                  </div>
                                  <p className="mb-1 text-grey">
                                    Some placeholder content in a paragraph.
                                  </p>
                                </a>
                                <hr />
                                <a
                                  href="#"
                                  className="list-group-item list-group-item-action"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1 text-white">
                                      Lender 2 Name
                                    </h5>
                                    <small className="text-white">
                                      3 Rating
                                    </small>
                                  </div>
                                  <p className="mb-1 text-grey">
                                    Some placeholder content in a paragraph.
                                  </p>
                                </a>
                                <hr />
                                <a
                                  href="#"
                                  className="list-group-item list-group-item-action"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1 text-white">
                                      Lender 3
                                    </h5>
                                    <small className="text-white">
                                      3.5 Rating
                                    </small>
                                  </div>
                                  <p className="mb-1 text-grey">
                                    Some placeholder content in a paragraph.
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-10">
                              <div
                                className="chart-content wow fadeInLeft"
                                data-wow-delay=".2s"
                              >
                                <ul
                                  className="nav nav-tabs"
                                  id="myTab"
                                  role="tablist"
                                >
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link active"
                                      id="tab-02036068106"
                                      data-bs-toggle="tab"
                                      data-bs-target="#tabb-02036068106"
                                      type="button"
                                      role="tab"
                                      aria-controls="tabb-02036068106"
                                      aria-selected="true"
                                    >
                                      Lenders message
                                    </button>
                                  </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                  <div
                                    className="tab-pane fade show active"
                                    id="tabb-02036068106"
                                    role="tabpanel"
                                    aria-labelledby="tab-02036068106"
                                  >
                                    <div className="chart-content-inner">
                                      <h2 className="title">Message Here</h2>
                                      <p>
                                        The World’s 1st ICO Platform That Offers
                                        Rewards and The platform helps investors
                                        to make easy to purchase and sell their
                                        tokens
                                      </p>
                                      <Link to={"/apply"} className="btn">
                                        Apply Now{" "}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- chart-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-96a8973 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="96a8973"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6933ee0"
              data-id="6933ee0"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-bee495d elementor-widget elementor-widget-roadmap"
                  data-id="bee495d"
                  data-element_type="widget"
                  data-widget_type="roadmap.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- roadMap-area --> */}
                    <section id="roadmap" className="roadmap-area">
                      <div className="container custom-container-two">
                        <div className="row justify-content-center">
                          <div className="col-xl-5 col-lg-8">
                            <div className="section-title text-center mb-60">
                              <span className="sub-title">Loan Process</span>
                              <h2 className="title">
                                Steps to apply for
                                <br />
                                <span>Loan</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="bt-roadmap_x">
                              <div className="bt-roadmap-wrap">
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 1</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">Fill the form</h4>
                                    <span>EVM support for parthians</span>
                                    <span>SubQuery Academy</span>
                                    <span>Proof of indexing</span>
                                  </div>
                                </div>
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 2</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">Submit Documents</h4>
                                    <span>
                                      SubQuery Builders/Grants Program
                                    </span>
                                    <span>
                                      SQT Network contract internal MVP
                                    </span>
                                    <span>Coordinator and client SDK</span>
                                  </div>
                                </div>
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 3</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">Pay the Fees</h4>
                                    <span>Public testnet launch</span>
                                    <span>
                                      SubQuery Network Explorer and dApp
                                    </span>
                                    <span>Point-in-time indexing</span>
                                  </div>
                                </div>
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 4</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">
                                      Document Verification
                                    </h4>
                                    <span>SQT token generation event</span>
                                    <span>
                                      Public incentivize testnet launch
                                    </span>
                                    <span>
                                      Data traffic insights and reporting
                                    </span>
                                  </div>
                                </div>
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 5</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">
                                      Personal Verification
                                    </h4>
                                    <span>
                                      Launch of the SubQuery Foundation
                                    </span>
                                    <span>
                                      Finalise research for other Layer-1 chains
                                    </span>
                                    <span>Liquidity mining program</span>
                                  </div>
                                </div>
                                <div className="bt-roadmap-item">
                                  <span className="roadmap-title">Step 6</span>
                                  <div className="roadmap-content">
                                    <span className="dot"></span>
                                    <h4 className="title">Step 6</h4>
                                    <span>Loan Amount Disbursment</span>
                                    <span>
                                      Finalise research for other Layer-1 chains
                                    </span>
                                    <span>Liquidity mining program</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- roadMap-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e1f73df elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="e1f73df"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-197cecd"
              data-id="197cecd"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9fdf0c9 elementor-widget elementor-widget-tg-team"
                  data-id="9fdf0c9"
                  data-element_type="widget"
                  data-widget_type="tg-team.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- style 2 --> */}

                    {/* <!-- team-area --> */}
                    <section id="team" className="team-area pt-130">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <div className="section-title text-center mb-70">
                              <span className="sub-title">Testimonials</span>
                              <h2 className="title">
                                Our Satisfied <br /> <span>Testimonials</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img01.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Cameron Williamson</h2>

                                <span className="designation">
                                  Founder &amp; CO
                                </span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-youtube"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img02.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Eleanor Pena</h2>

                                <span className="designation">
                                  Head of Design
                                </span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-youtube"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img03.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Bessie Cooper</h2>

                                <span className="designation">Vp People</span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-youtube"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img04.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Darlene Robertson</h2>

                                <span className="designation">
                                  Product Manager
                                </span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-youtube"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img05.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Jacob Jones</h2>

                                <span className="designation">Marketer</span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-flickr"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img06.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Courtney Henry</h2>

                                <span className="designation">Founder</span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-linkedin-in"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img07.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Ronald Richards</h2>

                                <span className="designation">
                                  Account Manager
                                </span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-linkedin-in"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <div className="team-item">
                              <div className="team-thumb">
                                <img
                                  decoding="async"
                                  src="./wp-content/uploads/2023/03/team_img08.png"
                                  alt=""
                                />
                              </div>

                              <div className="team-content">
                                <h2 className="title">Theresa Webb</h2>

                                <span className="designation">
                                  Founder &amp; CO
                                </span>

                                <div className="team-social">
                                  <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-twitter"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-instagram"></i>
                                  </a>

                                  <a href="#">
                                    <i className="fab fa-linkedin-in"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- team-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-948c8fc elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="948c8fc"
          data-element_type="section"
          id="contact-us"
          style={{ backgroundColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7bd79d3"
              data-id="7bd79d3"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-b432620 elementor-widget elementor-widget-contact-info"
                  data-id="b432620"
                  data-element_type="widget"
                  data-widget_type="contact-info.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- contact-area --> */}
                    <section id="contact" className="contact-area">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="section-title text-center mb-70">
                              <span className="sub-title">Contact</span>
                              <h2 className="title title">
                                <span>Contact</span> Wolflender Team
                              </h2>
                            </div>
                          </div>
                        </div>

                        <div className="contact-info-wrap">
                          <div className="row justify-content-center">
                            <div className="col-lg-4 col-sm-6">
                              <div className="contact-info-item">
                                <div className="icon">
                                  <span className="icon-background"></span>

                                  <i
                                    aria-hidden="true"
                                    className="fas fa-star"
                                  ></i>
                                </div>
                                <div className="content">
                                  <p className="contact-desc">
                                    company@gmail.com <br />
                                    infoweb@gmail.com
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                              <div className="contact-info-item">
                                <div className="icon">
                                  <span className="icon-background"></span>

                                  <i
                                    aria-hidden="true"
                                    className="fas fa-star"
                                  ></i>
                                </div>
                                <div className="content">
                                  <p className="contact-desc">
                                    +84 0977425031
                                    <br /> +998 765 775 34
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                              <div className="contact-info-item">
                                <div className="icon">
                                  <span className="icon-background"></span>

                                  <i
                                    aria-hidden="true"
                                    className="fas fa-star"
                                  ></i>
                                </div>
                                <div className="content">
                                  <p className="contact-desc">
                                    State/province/area: <br />
                                    Georgia 198
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contact-form-wrap">
                          <div
                            className="wpcf7 no-js"
                            id="wpcf7-f155-p57-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response">
                              <p
                                role="status"
                                aria-live="polite"
                                aria-atomic="true"
                              ></p>
                              <ul></ul>
                            </div>
                            <form
                              action="/wordpress/?simply_static_page=1#wpcf7-f155-p57-o1"
                              method="post"
                              className="wpcf7-form init"
                              aria-label="Contact form"
                              noValidate="novalidate"
                              data-status="init"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-grp">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-name"
                                    >
                                      <input
                                        size="40"
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Enter your Name"
                                        defaultValue=""
                                        type="text"
                                        name="your-name"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-grp">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-email"
                                    >
                                      <input
                                        size="40"
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Enter your email"
                                        defaultValue=""
                                        type="email"
                                        name="your-email"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-grp">
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-message"
                                >
                                  <textarea
                                    cols="40"
                                    rows="10"
                                    className="wpcf7-form-control wpcf7-textarea"
                                    aria-invalid="false"
                                    placeholder="Enter your massage"
                                    name="your-message"
                                  ></textarea>
                                </span>
                              </div>
                              <div className="submit-btn text-center">
                                <input
                                  className="wpcf7-form-control has-spinner wpcf7-submit btn"
                                  type="submit"
                                  defaultValue="Send Massage"
                                />
                              </div>
                              <div
                                className="wpcf7-response-output"
                                aria-hidden="true"
                              ></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <!-- contact-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default HomePage;
