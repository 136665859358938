import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginUserApi, registerUserApi } from "../../network/apiCalls";

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      const resposne = await registerUserApi(userData);
      if (resposne.data.status == 200) return resposne.data;
      return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const resposne = await loginUserApi(userData);
      if (resposne.data.status == 200) return resposne.data;
      return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state){
      state.userToken = null
      state.userInfo = {}
    }
  },
  extraReducers: (builer) => {
    // Register
    builer.addCase(registerUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builer.addCase(registerUser.fulfilled, (state, action) => {
      console.log("fulfilled:", action.payload);
      state.loading = false;
      state.success = true
      state.userInfo = action.payload.user
      state.userToken = action.payload.auth_token
    });
    builer.addCase(registerUser.rejected, (state, action) => {
      console.log("rejected:", action.payload);
      state.loading = false;
      state.error = action.payload;
    });
    
    // Login
    builer.addCase(loginUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builer.addCase(loginUser.fulfilled, (state, action) => {
      console.log("fulfilled:", action.payload);
      state.loading = false;
      state.success = true
      state.userInfo = action.payload.user
      state.userToken = action.payload.auth_token
    });
    builer.addCase(loginUser.rejected, (state, action) => {
      console.log("rejected:", action.payload);
      state.loading = false;
      state.error = action.payload;
    });

  },
});
const authReducer = authSlice.reducer;
export const { processStart, processEnd, logout } = authSlice.actions;
export default authReducer;
