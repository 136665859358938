import { Link } from "react-router-dom";
const BlogPost = (props) => {
  let item = props.blog;
  return (
    <article
      id="post-1"
      className="blog-post-item format-image post-1 post type-post status-publish format-standard hentry category-uncategorized"
    >
      <div className="blog-post-thumb">
        <a href="http://localhost/wordpress/what-are-the-best-blockchain-and-cryptocurrency-job-titles/">
          <img
            width="843"
            height="402"
            src={item.image}
            className="img-responsive wp-post-image"
            alt=""
            decoding="async"
            sizes="(max-width: 843px) 100vw, 843px"
          />{" "}
        </a>
      </div>
      <div className="blog-post-content">
        {/* <!-- blog meta --> */}

        <div className="blog-meta">
          <ul className="list-wrap">
            <li>
              <i className="far fa-user"></i>
              <a href="http://localhost/wordpress/author/wolflender/">
                wolflender
              </a>
            </li>

            <li>
              <i className="far fa-calendar-alt"></i> {item.created_at}
            </li>

            {/* <li>
              <i className="far fa-comments"></i>{" "}
              <a href="http://localhost/wordpress/hello-world/#comments">
                1 Comment
              </a>
            </li> */}
          </ul>
        </div>
        {/* <h2 className="title">
            <a href="http://localhost/wordpress/hello-world/">Hello world!</a>
          </h2> */}

        <div
          className={"post-text "+(props.isShort ? 'short-blog':'')}
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>

        {/* <!-- blog btn --> */}
        {props.isShort && (
          <>
           <div class="fadeout"></div>
            <div className="tg-blog-post-bottom">
              <Link to={"/blog/" + item.blog_id} className="btn btn-two">
                Read More <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
            
          </>
        )}
      </div>
    </article>
  );
};
export default BlogPost;
