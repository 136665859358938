import axios from "axios";
import { transformToFormData } from "../utils/transformToFromData";
const BASEURL = "https://solutionspool.in/wolflender-backend/api"//"http://localhost/wolflender-backend/api";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
function getAuthToken() {
  if (localStorage.getItem("authState")) {
    try {
      let token = JSON.parse(localStorage.getItem("authState")).userToken;
      return token;
    } catch (err) {
      console.log("Error:", err);
      return "";
    }
  }
  return "";
}

function commonHeaders(){
  return {
    Authorization: getAuthToken(),
    "Content-Type":"multipart/form-data"
  }
}

export const registerUserApi = async (userData) => {
  console.warn("Request:", userData);
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/auth/register",
    data: userData,
  });
  return resposne;
};

export const loginUserApi = async (loginDetails) => {
  console.warn("Request:", loginDetails);
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/auth/login",
    data: loginDetails,
  });
  return resposne;
};

export const submitApplicationApi = async (applicationForm) => {
  console.warn("Request:", applicationForm);

  const formData = new FormData()

    // personal
        formData.append('first_name',applicationForm.personal.firstName)
        formData.append('last_name',applicationForm.personal.lastName)
        formData.append('email',applicationForm.personal.email)
        formData.append('phone_code',applicationForm.personal.phoneCode)
        formData.append('mobile_no',applicationForm.personal.mobile_no)
        formData.append('country',applicationForm.personal.country)
        formData.append('address',applicationForm.personal.address)
        formData.append('mobile_no',applicationForm.personal.mobile_no)
  // documents
        formData.append('tax_id',applicationForm.documents.taxid[0])
        formData.append('gov_id_front',applicationForm.documents.govIdFront[0])
        formData.append('gov_id_back',applicationForm.documents.govIdBack[0])
        formData.append('bank_statement',applicationForm.documents.bankStatement[0])
  // loan details
        formData.append('loan_type',applicationForm.loanDetails.loanType)
        formData.append('biance_id',applicationForm.loanDetails.bianceId)
        formData.append('loan_amount',applicationForm.loanDetails.loanAmount)
        formData.append('wallet_qr',applicationForm.loanDetails.walletQr[0])
        formData.append('relative_name',applicationForm.loanDetails.releativeName) 
        formData.append('relative_mobile',applicationForm.loanDetails.releativeMobile)
  //lenders
        formData.append('lenders',applicationForm.lender.lenders.join(','))

  //payments
        formData.append('payment_ss',applicationForm.payment.paymentSS[0])


  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/submit-application",
    data: formData,
    headers:commonHeaders()
  });
  return resposne;
};


export const applicationListApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/application-list",
    headers:commonHeaders()
  });
  return resposne;
};

export const blogsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/auth/blogs",
    headers:commonHeaders()
  });
  return resposne;
};

export const testimonialsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/auth/testimonials",
    headers:commonHeaders()
  });
  return resposne;
};
export const paymentConfigApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/auth/payment_config",
    headers:commonHeaders()
  });
  return resposne;
};
export const blogDetailsApi = async (id) => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/auth/blog/"+id,
    headers:commonHeaders()
  });
  return resposne;
};
export const validateResponse = (response,action) => {
  if(response.status == 401){
    action()
  }
}