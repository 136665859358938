import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Step1 from "../components/apply-steps/step1";
import Step2 from "../components/apply-steps/step2";
import Step3 from "../components/apply-steps/step3";
import Step4 from "../components/apply-steps/step4";
import Step5 from "../components/apply-steps/step5";


const ApplyPage = () => {
 
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const steps = [
    {
      title: "Personal Details",
      icon: <i className="fa fa-user"></i>,
      element: <Step1 />,
    },
    {
      title: "Upload Documents",
      icon: <i className="fa fa-file"></i>,
      element: <Step2 />,
    },
    {
      title: "Loan Details",
      icon: <i className="fa fa-inr"></i>,
      element: <Step3 />,
    },
    {
      title: "Lender",
      icon: <i className="fa fa-users"></i>,
      element: <Step4 />,
    },
    {
      title: "Wallet Verification",
      icon: <i className="fa fa-bold"></i>,
      element: <Step5 />,
    },
  ];

  const [appList, setAppList] = useState([]);

  useEffect(() => {
    if (!auth.userToken) {
      navigate("/login");
    }
  }, []);

  if (!auth.userToken) {
    return null;
  }

  return (
    <>
      <div className="container my-5">
        <div className="px-lg-5 pb-5">
          <ul className="list-group">
            {appList.length > 0 &&
              appList.map((item,index) => {
                return (
                  <li className="list-group-item d-flex flex-column" key={index}>
                    <div>
                      <small className="text-muted fst-italic">
                        Application sent on {item.created_at}
                      </small>
                    </div>
                    <div className="mb-3">
                      <i className="fa fa-refresh"></i> Application is in
                      progress.
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>

        <CustomStepper steps={steps} />
      </div>
    </>
  );
};

const CustomStepper = ({ steps }) => {
  const applyForm = useSelector((state) => state.applyForm);
  return (
    <>
      <div className="steps d-flex flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
        {steps.length > 0 &&
          steps.map((step, index) => {
            return (
              <div
                className={
                  "step " + (index <= applyForm.activeTab ? "completed" : "")
                }
                key={index}
              >
                <div className="step-icon-wrap">
                  <div className="step-icon">{step.icon}</div>
                </div>
                <h4 className="step-title">{step.title}</h4>
              </div>
            );
          })}
      </div>
      <div className="tab-content apply-box">
        {steps.length > 0 &&
          steps.map((step, index) => {
            return (
              <div
                className={
                  "tab-pane container " +
                  (applyForm.activeTab == index ? "active" : "fade")
                }
                key={index}
              >
                <div className="px-lg-5 pb-5">
                  <h4 className="text-white border-bottom pb-4 mb-4">
                    {step.title}
                  </h4>
                  {step.element}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ApplyPage;
