import { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetForm } from "../redux/features/applyFormSlice";
const SiteHeader = () => {
  const disptach = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const menuItems = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/about-us" },
    { title: "Apply", path: "/apply" },
    { title: "Blog", path: "/blogs" },
    { title: "Contact Us", path: "/contact-us" },
    { title: "Testimonials", path: "/testimonials" },
  ];
  const hideMobileMenu = () => {
    $("body").removeClass("mobile-menu-visible");
  };
  useEffect(() => {
    if ($(".mobile-menu").length) {
      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(300);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(
        ".menu-backdrop, .mobile-menu .close-btn, .mobile-menu .navigation li a"
      ).on("click", hideMobileMenu);
    }
    document.getElementById("preloader").style.display = "none";
  }, []);

  function logoutUser() {
    disptach(logout());
    disptach(resetForm());
    toast.success("Logout successfully");
    navigate("/");
  }

  return (
    <>
      <div id="preloader">
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
      <header id="header">
        <div id="header-fixed-height"></div>
        <div
          id="sticky-header"
          className="menu-area"
          style={{ backgroundColor: "#004966" }}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to={"/"} className="main-logo">
                        <img
                          src="/wp-content/themes/bigtech/assets/img/logo/logo.png"
                          alt="Logo"
                        />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul id="menu-home-page-menu" className="navigation">
                        {menuItems.map((item, index) => {
                          return (
                            <li
                              key={index}
                              id="menu-item-264"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-264"
                            >
                              <Link to={item.path} title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="header-action d-none d-md-block">
                      <ul>
                        <li className="header-btn">
                          {auth.userToken ? (
                            <div className=" text-white">
                              <Link to={"/profile"}>
                                <span className="me-3 text-white">
                                  <i className="fa fa-user-circle"></i>{" "}
                                  {auth.userInfo.fname}
                                </span>
                              </Link>
                              <button className="btn" onClick={logoutUser}>
                                <i className="fa fa-sign-out"></i> Logout
                              </button>
                            </div>
                          ) : (
                            <>
                              <Link className="btn" to="/register">
                                Register
                              </Link>
                              <Link className="btn" to="/login">
                                Login
                              </Link>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* <!-- Mobile Menu  --> */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo">
                      <a href="./">
                        <img
                          src="./wp-content/themes/bigtech/assets/img/logo/secondary_logo.png"
                          alt="Logo"
                        />
                      </a>
                    </div>
                    <div className="menu-outer">
                      <ul id="menu-home-page-menu-1" className="navigation">
                        {menuItems.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-264"
                            >
                              <Link to={item.path} title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="d-flex justify-content-between p-3 align-items-center">
                      {auth.userToken ? (
                        <>
                          <Link to={"/profile"} onClick={hideMobileMenu}>
                            <span className="me-3 text-white">
                              <i className="fa fa-user-circle"></i>{" "}
                              {auth.userInfo.fname}
                            </span>
                          </Link>
                          <button className="btn" onClick={logoutUser}>
                            Logout
                          </button>
                        </>
                      ) : (
                        <>
                          <Link
                            className="btn"
                            to="/register"
                            onClick={hideMobileMenu}
                          >
                            Register
                          </Link>
                          <Link
                            className="btn"
                            to="/login"
                            onClick={hideMobileMenu}
                          >
                            Login
                          </Link>
                        </>
                      )}
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default SiteHeader;
