import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../redux/features/authSlice";
import { useState } from "react";
const LoginPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passView, setPassView] = useState(false);
  const onSubmit = async (data) => {
    try {
      console.log(data);
      const result = await dispatch(loginUser(data)).unwrap();
      reset();
      toast.success("Logged in successfully");
      navigate("/");
      console.log("log 1", result);
    } catch (error) {
      toast.error(error);
      console.log("log 2", error);
    }
  };

  return (
    <>
      <main className="main-area">
        <div
          data-elementor-type="wp-page"
          data-elementor-id="57"
          className="elementor elementor-57"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-17b794a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="17b794a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61c4005"
                data-id="61c4005"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-1b94da1 elementor-widget elementor-widget-hero-banner"
                    data-id="1b94da1"
                    data-element_type="widget"
                    data-widget_type="hero-banner.default"
                  >
                    <div className="elementor-widget-container">
                      {/* <!-- banner-area --> */}
                      <section className="banner-area banner-bg mx-2 mx-md-0 pt-3 pt-md-5">
                        <div className="banner-shape-wrap">
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape01.png"
                            alt=""
                            className="img-one"
                          />
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape02.png"
                            alt=""
                            className="img-two"
                          />
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape03.png"
                            alt=""
                            className="img-three"
                          />
                        </div>
                        <div className="container mb-5">
                          <div className="row justify-content-center">
                            <div
                              className="col-md-7 pe-0 shadow rounded"
                              style={{ background: "#00000073" }}
                            >
                              <div className="form-left h-100 py-5 px-3 px-md-5 apply-box">
                                <h3 className="mb-3 text-white">Login</h3>
                                <form
                                  action=""
                                  className="row g-4"
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="col-12 form-group">
                                    <label>
                                      Email/Mobile Number
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        <i className="fa fa-user"></i>
                                      </div>
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.username
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Email/Mobile number"
                                        {...register("username", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 form-group">
                                    <label>
                                      Password
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text bg-black text-white">
                                        <i className="fa fa-key"></i>
                                      </div>
                                      <input
                                        type={passView ? "text" : "password"}
                                        className={
                                          "form-control " +
                                          (errors.password
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Password"
                                        {...register("password", {
                                          required: true,
                                        })}
                                      />
                                      <div
                                        className="input-group-text bg-black text-white"
                                        onClick={() => setPassView(!passView)}
                                      >
                                        <i
                                          className={
                                            "fa fa-eye" +
                                            (passView ?  "" : "-slash")
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <p className="text-white">
                                      Don't have an account ?
                                    </p>
                                  </div>

                                  <div className="col-sm-6">
                                    <a
                                      href="#"
                                      className="float-end text-primary"
                                    >
                                      Forgot Password?
                                    </a>
                                  </div>

                                  <div className="col-12">
                                    <Link
                                      to={"/register"}
                                      className="btn float-start mt-4"
                                    >
                                      Register Now{" "}
                                    </Link>
                                    <button
                                      type="submit"
                                      className="btn float-end mt-4"
                                      disabled={auth.loading}
                                    >
                                      {auth.loading ? "Please wait" : "Login"}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <!-- banner-area-end --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );

};

export default LoginPage;
