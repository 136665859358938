import { useEffect, useState } from "react";
import { blogsApi } from "../network/apiCalls";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import BlogPost from "../components/BlogPost";
const BlogPage = () => {
  const [blogs, setBLogs] = useState([]);

  const fetchBlogs = async () => {
    try {
      let response = await blogsApi();
      if (response.status == 200 && response.data.status == 200) {
        setBLogs(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <main className="main-area mb-5">
      {/* <!-- breadcrumb-area --> */}
      <section
        className="breadcrumb-area breadcrumb-bg blog-bg"
        data-background="http://localhost/wordpress/wp-content/uploads/2023/06/breadcrumb-bg.jpg"
        data-bg-color=""
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <h2 className="title">Blogs</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb-area-end --> */}

      <section className="blog-area pt-130 pb-130 bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="postbox__wrapper">
                <header>
                  <h1 className="page-title screen-reader-text">Blog</h1>
                </header>
                {blogs.map((item, index) => {
                  return <BlogPost blog={item} key={index} isShort={true}/>;
                })}

                <nav className="pagination-wrap"></nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};


export default BlogPage;
