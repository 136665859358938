import { useEffect,useState } from "react";
import { testimonialsApi } from "../network/apiCalls";
import { toast } from "react-toastify";
const Testimonials = () => {

    const [testimonials, settestimonials] = useState([]);

    const fetchTestimonials = async () => {
      try {
        let response = await testimonialsApi();
        if (response.status == 200 && response.data.status == 200) {
            settestimonials(response.data.data);
        }
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      }
    };
    useEffect(() => {
        fetchTestimonials();
    }, []);

  return (
    <main className="main-area mb-5 ">
      <div
        data-elementor-type="wp-page"
        data-elementor-id="57"
        className="elementor elementor-57"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e1f73df elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="e1f73df"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-197cecd"
              data-id="197cecd"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9fdf0c9 elementor-widget elementor-widget-tg-team"
                  data-id="9fdf0c9"
                  data-element_type="widget"
                  data-widget_type="tg-team.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- style 2 --> */}

                    {/* <!-- team-area --> */}
                    <section id="team" className="team-area pt-130">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <div className="section-title text-center mb-70">
                              <span className="sub-title">Testimonials</span>
                              <h2 className="title">
                                Our Satisfied <br /> <span>Testimonials</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          {testimonials.length && testimonials.map((item, key) => {
                            return (
                              <div className="col-xl-3 col-md-4 col-sm-6" key={key}>
                                <div className="team-item">
                                  <YoutubeEmbed url={item.video_url} />
                                  <div className="team-content">
                                    <h2 className="title">{item.text}</h2>

                                    <span className="designation">
                                        {[...Array(5).keys()].map(count=>{
                                            return (count < item.rating ? <i className="fa fa-star" key={count}></i> : <i className="far fa-star" key={count}></i> )
                                        })}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                    {/* <!-- team-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default Testimonials;

const YoutubeEmbed = ({ url }) => (
  <div className="video-responsive my-3">
    <iframe
    style={{border:"2px solid white"}}
      width="853"
      height="480"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);
