import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  nextTab,
  setLender,
  setLoanDetails,
} from "../../redux/features/applyFormSlice";
import { useDispatch } from "react-redux";
const Step4 = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { lenders: [] } });

  const onSubmit = (data) => {
    console.log("data",data)
    if (data.lenders.length != 3) {
      setError("lenders", { message: "Please select 3 lenders" });
      console.log(errors)
      return;
    }
    // console.log("step 4", data);
    dispatch(setLender(data));
    dispatch(nextTab());
  };
  const lendersList = [
    { name: "Lender 1" },
    { name: "Lender 2" },
    { name: "Lender 3" },
    { name: "Lender 4" },
    { name: "Lender 5" },
  ];
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-lg-12 form-group">
            <label>Choose any 3 lender</label>
            <ul className="list-group">
              {lendersList.length > 0 &&
                lendersList.map((item, index) => {
                  return (
                    <li
                      className="list-group-item"
                      key={index}
                      style={{ background: "#0000004f", color: "white" }}
                    >
                      <input
                        className="form-check-input me-1 me-2"
                        type="checkbox"
                        value={item.name}
                        {...register("lenders", {
                          required: "Please select 3 lenders",
                        })}
                      />
                      {item.name}
                    </li>
                  );
                })}
            </ul>
            <p className="text-red">
              <ErrorMessage errors={errors} name="lenders" />
            </p>
          </div>
        </div>
        <StepNavigation />
      </form>
    </>
  );
};
export default Step4;
