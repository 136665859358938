import { useState } from "react";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import HomePage from "./HomePage";
import ApplyPage from "./ApplyPage";
import LoginPage from "./LoginPage";
import BlogPage from "./BlogPage";
import RegisterPage from "./RegisterPage";

import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogPostDetails from "./BlogPostDetails";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Testimonials from "./Testimonials";
import ProfilePage from "./Profile";
const MainSite = () => {
  return (
    <>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/testimonials" element={<Testimonials/>} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPostDetails />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/apply" element={<ApplyPage />} />
      </Routes>
      <SiteFooter />
      <ToastContainer />
    </>
  );
};
export default MainSite;
