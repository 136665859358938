import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../redux/features/authSlice";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      const result = await dispatch(registerUser(data)).unwrap();
      reset();
      toast.success("Logged in successfully");
      navigate("/");
      //console.log("log 1",result)
    } catch (error) {
      toast.error(error);
      console.log("log 2", error);
    }
  };

  return (
    <>
      <main className="main-area">
        <div
          data-elementor-type="wp-page"
          data-elementor-id="57"
          className="elementor elementor-57"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-17b794a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="17b794a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61c4005"
                data-id="61c4005"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-1b94da1 elementor-widget elementor-widget-hero-banner"
                    data-id="1b94da1"
                    data-element_type="widget"
                    data-widget_type="hero-banner.default"
                  >
                    <div className="elementor-widget-container">
                      {/* <!-- banner-area --> */}
                      <section className="banner-area banner-bg mx-2 mx-md-0 pt-3 pt-md-5">
                        <div className="banner-shape-wrap">
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape01.png"
                            alt=""
                            className="img-one"
                          />
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape02.png"
                            alt=""
                            className="img-two"
                          />
                          <img
                            decoding="async"
                            src="./wp-content/uploads/2023/03/banner_shape03.png"
                            alt=""
                            className="img-three"
                          />
                        </div>
                        <div className="container mb-5">
                          <div className="row justify-content-center">
                            <div
                              className="col-md-7 pe-0 shadow rounded"
                              style={{ background: "#00000073" }}
                            >
                              <div className="form-left h-100 py-5  px-3 px-md-5 apply-box">
                                <h3 className="mb-3 text-white">Register</h3>
                                {/* <div className="text-white">{JSON.stringify(regForm)}</div> */}
                                <form
                                  action=""
                                  className="row g-4"
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="col-md-6 form-group">
                                    <label>
                                      First Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        F
                                      </div>
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.fname ? "danger-border" : "")
                                        }
                                        placeholder="Enter First Name"
                                        {...register("fname", {
                                          required: true,
                                          minLength: 3,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 form-group">
                                    <label>
                                      Last Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        L
                                      </div>
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.lname ? "danger-border" : "")
                                        }
                                        placeholder="Enter Last Name"
                                        {...register("lname", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label>
                                      Mobile Number
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        <i className="fa fa-phone"></i>
                                      </div>
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.mobile_no
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Mobile Number"
                                        {...register("mobile_no", {
                                          required: true,
                                          maxLength: 12,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label>
                                      Email
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        <i className="fa fa-envelope"></i>
                                      </div>
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.email ? "danger-border" : "")
                                        }
                                        placeholder="Enter Email"
                                        {...register("email", {
                                          required: true,
                                          pattern:
                                            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label>
                                      Password <small>(min 6 chars)</small>
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text  bg-black text-white">
                                        <i className="fa fa-key"></i>
                                      </div>
                                      <input
                                        type="password"
                                        className={
                                          "form-control " +
                                          (errors.password
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Password"
                                        {...register("password", {
                                          required: true,
                                          minLength: 6,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <button
                                      type="submit"
                                      className="btn float-end mt-4"
                                      disabled={auth.loading}
                                    >
                                      {auth.loading
                                        ? "Please wait"
                                        : "Register"}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <!-- banner-area-end --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );

  return (
    <>
      <div className="container my-5">
        <div className="login-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 px-md-2 px-0">
                <div className="login-bg1 shadow rounded">
                  <div className="row">
                    <div className="col-md-7 pe-0"></div>
                    <div className="col-md-5 ps-0 d-none d-md-block">
                      <div className="form-right h-100 login-bg2 text-white text-center pt-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
