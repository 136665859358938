import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { nextTab, setLoanDetails } from "../../redux/features/applyFormSlice";
import { useDispatch,useSelector } from "react-redux";
const Step3 = () => {
  const applyForm = useSelector(state=>state.applyForm)
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log("step 3", data);
    dispatch(setLoanDetails(data))
    dispatch(nextTab());
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Loan Type</label>
            <input
              type="text"
              readOnly
              className={
                "form-control" + (!errors.taxid ? "" : " danger-border")
              }
              {...register("loanType", {
                required: "This field is requied",
                value: "Personal Loan",
              })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="loanType" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Enter Binance ID</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.bianceId ? "" : " danger-border")
              }
              {...register("bianceId", { required: "This field is requied" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="bianceId" />
            </p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>
              Loan Amount <small>(Max: 40000)</small>
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
              <input
                type="number"
                className={
                  "form-control" + (!errors.loanAmount ? "" : " danger-border")
                }
                {...register("loanAmount", { required: "This field is requied" })}
              />
            </div>
            <p className="text-red mb-3">
                <ErrorMessage errors={errors} name="loanAmount" />
              </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Upload Crypto Wallet QR For Loan Receiving Purpose</label>
            <input
              type="file"
              className={
                "form-control" + (!errors.walletQr ? "" : " danger-border")
              }
              {...register("walletQr", { required: "This field is requied" })}
              accept="application/pdf, image/jpg,image/jpeg,image/png"
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="walletQr" />
            </p>
          </div>
        </div>
        <h4 className="text-white m-0">Relative Details</h4>
        <hr className="mb-4" />
        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Father/Relative Name</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.releativeName ? "" : " danger-border")
              }
              {...register("releativeName", { required: "This field is requied" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="releativeName" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Mobile</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.releativeMobile ? "" : " danger-border")
              }
              {...register("releativeMobile", { required: "This field is requied" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="releativeMobile" />
            </p>
          </div>
        </div>
        <StepNavigation />
      </form>
    </>
  );
};
export default Step3;
