import React from "react";
import { useForm, useController } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { nextTab, setPersonal } from "../../redux/features/applyFormSlice";
import StepNavigation from "./stepNavigation";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
const codeList = require("./../../utils/countryCode.json");

const Step1 = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const { field } = useController({
    name: "country",
    rules: { required: "This field is requied" },
    control,
  });
  const {
    value: countryValue,
    onChange: countryOnChange,
    ...restLangField
  } = field;

  const { field: phoneCodeField } = useController({
    name: "phoneCode",
    rules: { required: "This field is requied" },
    control,
  });
  const {
    value: phoneCodeValue,
    onChange: phoneCodeOnChange,
    ...restPhoneCodeField
  } = phoneCodeField;

  const onSubmit = (data) => {
    dispatch(setPersonal(data));
    dispatch(nextTab());
  };

  const countryList = codeList.map((item) => {
    return { value: item.name, label: item.name };
  });
  const phoneCodeList = codeList.map((item) => {
    return { value: item.dial_code, label: item.dial_code };
  });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>First Name</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.firstName ? "" : " danger-border")
              }
              {...register("firstName", {
                required: "This field is requied",
                value: auth.userInfo.fname,
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Invalid value entered ",
                },
              })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="firstName" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Last Name</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.lastName ? "" : " danger-border")
              }
              {...register("lastName", {
                required: "This field is requied",
                value: auth.userInfo.lname,
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Invalid value entered ",
                },
              })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="lastName" />
            </p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Email Id Used In Crypto Wallet</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.email ? "" : " danger-border")
              }
              {...register("email", {
                required: "This field is requied",
                value: auth.userInfo.email,
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Enter valid email address",
                },
              })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="email" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Contact Number Used In Crypto Id </label>
            <div className="row g-0">
              <div className="" style={{ width: "110px" }}>
                <Select
                  placeholder="Code"
                  options={phoneCodeList}
                  value={
                    phoneCodeValue
                      ? phoneCodeList.find((x) => x.value === phoneCodeValue)
                      : phoneCodeValue
                  }
                  onChange={(option) =>
                    phoneCodeOnChange(option ? option.value : option)
                  }
                  {...restPhoneCodeField}
                  className={!errors.phoneCode ? "" : " danger-border"}
                  styles={{
                    option: (baseStyle, state) => {
                      return { ...baseStyle, color: "black" };
                    },
                    container: (baseStyle, state) => {
                      return { ...baseStyle, zIndex: 10 };
                    },
                    control: (baseStyle, state) => {
                      return { ...baseStyle, height: "20px" };
                    },
                  }}
                />
                <p className="text-red">
                  <ErrorMessage errors={errors} name="phoneCode" />
                </p>
              </div>
              <div className="col">
                <input
                  type="text"
                  className={
                    "form-control" + (!errors.mobile_no ? "" : " danger-border")
                  }
                  {...register("mobile_no", {
                    required: "This field is requied",
                    value: auth.userInfo.mobile_no,
                    minLength: {
                      value: 10,
                      message: "Mobile no must have 10 digits",
                    },
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Invalid mobile number",
                    },
                  })}
                />
                <p className="text-red">
                  <ErrorMessage errors={errors} name="mobile_no" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-4 form-group">
            <label>Country</label>
            <Select
              placeholder="Select Country"
              isClearable
              options={countryList}
              value={
                countryValue
                  ? countryList.find((x) => x.value === countryValue)
                  : countryValue
              }
              onChange={(option) =>
                countryOnChange(option ? option.value : option)
              }
              {...restLangField}
              className={!errors.country ? "" : " danger-border"}
              styles={{
                option: (baseStyle, state) => {
                  return { ...baseStyle, color: "black" };
                },
                container: (baseStyle, state) => {
                  return { ...baseStyle, zIndex: 9 };
                },
                control: (baseStyle, state) => {
                  return { ...baseStyle, height: "20px" };
                },
              }}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="country" />
            </p>
          </div>
          <div className="col-lg-8 form-group">
            <label>Address</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.country ? "" : " danger-border")
              }
              {...register("address", { required: "This field is requied" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="address" />
            </p>
          </div>
        </div>
        <StepNavigation />
      </form>
    </>
  );
};
export default Step1;
