import { useState,useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import BlogPost from "../components/BlogPost";
import { blogDetailsApi } from "../network/apiCalls";
import { toast } from "react-toastify";
const BlogPostDetails = () =>{
  const {id} = useParams()
  const navigate = useNavigate()

  const [blog, setBlog] = useState(null);

  const fetchBlog = async (id) => {
    try {
      let response = await blogDetailsApi(id);
      if (response.status == 200 && response.data.status == 200) {
        setBlog(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchBlog(id);
  }, []);

    return (
        <main className="main-area mb-5">
        {/* <!-- breadcrumb-area --> */}
        <section
          className="breadcrumb-area breadcrumb-bg blog-bg"
          data-background="http://localhost/wordpress/wp-content/uploads/2023/06/breadcrumb-bg.jpg"
          data-bg-color=""
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <h2 className="title">Blog</h2>
                  <h3 className="text-white">{blog && blog.title}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}
  
        <section className="blog-area pt-130 pb-130 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="postbox__wrapper">
                  <header>
                    <h1 className="page-title screen-reader-text">Blog</h1>
                  </header>
                  {blog ? (
                    <BlogPost blog={blog} />
                  ) : (
                    <h1>Please wait</h1>
                  )}
                  
                  <nav className="pagination-wrap"></nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
}
export default BlogPostDetails