import { useDispatch, useSelector } from "react-redux";
import { prevTab } from "../../redux/features/applyFormSlice";
const StepNavigation = () => {
  const disptach = useDispatch();
  const applyForm = useSelector((state) => state.applyForm);
  return (
    <div className="d-flex justify-content-between px-5">
      {applyForm.loading == false && applyForm.activeTab > 0 ? (
        <button
          type="button"
          className="btn"
          onClick={() => disptach(prevTab())}
        >
          Back
        </button>
      ) : (
        <span></span>
      )}
      {applyForm.loading == false && applyForm.activeTab < applyForm.stepCount ? (
        <button type="submit" className="btn">
          {applyForm.stepCount - 1 == applyForm.activeTab ? "Submit" :  "Next"}
        </button>
      ) : (
        <span className="text-white">{applyForm.loading ? 'Please wait...' : ''}</span>
      )}
    </div>
  );
};
export default StepNavigation;
