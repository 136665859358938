import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { submitApplicationApi } from "../../network/apiCalls";
const initialState = {
  loading:false,
  activeTab: 0,
  stepCount: 5,
  personal: {},
  documents: {},
  loanDetails: {},
  lender: {},
  payment: {},
};

export const submitApplication = createAsyncThunk(
  "application/submit",
  async (_,thunkAPI) => {
    try {
      let formState  = thunkAPI.getState().applyForm
      const resposne = await submitApplicationApi(formState);
      if (resposne.data.status == 200) return resposne.data;
      return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const applyFormSlice = createSlice({
  name: "applyForm",
  initialState,
  reducers: {
    nextTab: (state) => {
      console.log("next called");
      state.activeTab = state.activeTab + 1;
    },
    prevTab: (state) => {
      console.log("Prev called");
      state.activeTab = state.activeTab - 1;
    },
    setPersonal: (state, action) => {
      state.personal = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setLoanDetails: (state, action) => {
      state.loanDetails = action.payload;
    },
    setLender: (state, action) => {
      state.lender = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
    resetForm: (state)=>{
      state = initialState
    }
  },
  extraReducers: (builder)=> {
    builder.addCase(submitApplication.pending,(state) => {
      state.loading = true;
    })
    builder.addCase(submitApplication.fulfilled,(state, action) => {
      state.loading = false;
    })
    builder.addCase(submitApplication.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

const applyFromReducer = applyFormSlice.reducer;
export const {
  setPersonal,
  setDocuments,
  setLender,
  setLoanDetails,
  setPayment,
  nextTab,
  prevTab,
  resetForm
} = applyFormSlice.actions;
export default applyFromReducer;
